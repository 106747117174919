import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {AppLayoutMain, HeaderBar, NavigationBar, Tab, TabBar, TabPanel, TabSet, useStyles, ContentBlock, ContentItem, Badge} from '@ace-de/ui-components';
import {alfInvoiceChannelTypes, alfInvoiceStatusTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';
import InvoiceHeader from './ui-elements/InvoiceHeader';
import invoiceOverviewScreenTabs from './invoiceOverviewScreenTabs';
import BurgerMenu from './ui-elements/BurgerMenu';
import * as invoiceSelectors from './invoiceSelectors';
import InvoiceOverviewServicePanel from './ui-elements/InvoiceOverviewServicePanel';
import ManualCheckFailedBanner from './ui-elements/ManualCheckFailedBanner';
import InvoiceOverviewBaseDataPanel from './ui-elements/InvoiceOverviewBaseDataPanel';
import InvoiceDocumentUploadPanel from './ui-elements/InvoiceDocumentUploadPanel';
import InternalNotesPanel from './ui-elements/InternalNotesPanel';
import InvoiceReadOnlyBanner from './ui-elements/InvoiceReadOnlyBanner';
import InvoiceRelevantLocationsMap from './ui-elements/InvoiceRelevantLocationsMap';
import CaseProtocolPanel from '../case-logs/CaseProtocolPanel';
import FilterPanel from '../case-logs/FilterPanel';
import * as invoiceActionTypes from './invoiceActionTypes';
import {formatInvoiceServiceLine} from './formatInvoiceServiceLine';
import TaskListPanel from '../tasks/ui-elements/TaskListPanel';
import InvoiceCommunicationTab from './InvoiceCommunicationTab';
import SCOverviewTab from '../service-cases/SCOverviewTab';
import * as applicationActionTypes from '../application/applicationActionTypes';
import errorTypes from '../application/errorTypes';
import MIAFormTab from './MIAFormTab';
import leaAccessControl from '../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';

const InvoiceOverviewScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('invoice_overview_screen');
    const {activeTab} = useSearchQueryParams();
    const {invoice, services, location, match, taskCountIndicator} = props;
    const {initiateInvoiceScreenRedirection, initiateManualInvoiceStatusUpdateFlow, initiateApprovalError} = props;
    const [invoiceServices, setInvoiceServices] = useState([]);
    const isInvoiceUpdateAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.UPDATE,
    );
    const areInternalNotesAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.INTERNAL_NOTES,
        leaFeatureActions.READ,
    );
    const isTaskTabAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.TASKS,
        leaFeatureActions.READ,
    );

    useEffect(() => {
        initiateInvoiceScreenRedirection({
            match,
            location,
        });
    }, [initiateInvoiceScreenRedirection, match, location]);

    useEffect(() => {
        const serviceList = invoice?.lines.map(invoiceLine => {
            const invoiceServiceValue = [...services].find(serviceValue => (invoiceLine.service === serviceValue.type));
            if (!invoiceServiceValue) return null;
            return {
                ...invoiceLine,
                code: invoiceServiceValue.code,
                lineNo: invoiceLine.lineNo,
                approvedAmount: invoiceLine.approvedAmount?.toFixed(2) || invoiceLine.requestedAmount?.toFixed(2),
            };
        }).sort((invoiceServiceA, invoiceServiceB) => {
            return invoiceServiceA.code - invoiceServiceB.code;
        });
        setInvoiceServices(serviceList);
    }, [services, invoice]);

    const handleApprovedAmountChange = (value, lineNo) => {
        const service = invoiceServices.find(invoiceService => invoiceService.lineNo === lineNo);
        if (!service) return;
        service.approvedAmount = value;
        const index = invoiceServices.indexOf(service);
        setInvoiceServices(prevState => {
            prevState.splice(index, 1, service);
            return [...prevState];
        });
    };

    const handleInvoiceApproval = statusData => {
        const invoiceServiceLines = formatInvoiceServiceLine(invoiceServices, invoice.lines);

        const isInvoiceOverpaid = invoiceServices.some(invoiceServiceLine => (
            (invoiceServiceLine.assignedAmount && Math.round(Number(invoiceServiceLine.approvedAmount?.replace(',', '.')) * 100) / 100 > invoiceServiceLine.assignedAmount)
            || (invoiceServiceLine.budgetAmount && Math.round(Number(invoiceServiceLine.approvedAmount?.replace(',', '.')) * 100) / 100 > invoiceServiceLine.budgetAmount)
        ));

        const isInvoicePartialPayment = invoiceServices.some(invoiceServiceLine => (
            invoiceServiceLine.requestedAmount > Math.round(Number(invoiceServiceLine.approvedAmount?.replace(',', '.')) * 100) / 100
        ));

        if ((!invoice?.accountParty || !invoice.accountParty.id)
            && (invoice.type === alfInvoiceTypes.EKR || invoice.type === alfInvoiceTypes.VKR)
        ) {
            initiateApprovalError({
                errorType: invoice.type === 'EKR'
                    ? errorTypes.CREDITOR_MISSING
                    : errorTypes.DEBTOR_MISSING,
            });
            return;
        }

        initiateManualInvoiceStatusUpdateFlow({
            statusData,
            invoiceServiceLines,
            invoiceId: invoice.id,
            isInvoiceOverpaid,
            isInvoicePartialPayment,
        });
    };

    if (!invoice || !services) return null;

    const {failedINAConstraints, status} = invoice;
    const isReadOnly = ![
        alfInvoiceStatusTypes.IN_CREATION,
        alfInvoiceStatusTypes.OPEN,
        alfInvoiceStatusTypes.MANUAL_CHECK,
        alfInvoiceStatusTypes.CLOSED,
    ].includes(status);

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation', 'ace-c-header-bar--is-sticky')}>
                <InvoiceHeader invoice={invoice} />
            </HeaderBar>
            <TabSet
                name="invoice-overview-screen-tab-set"
                isRoutingEnabled={true}
            >
                <NavigationBar className={cx('ace-c-navigation-bar--is-sticky')}>
                    <div
                        className={cx([
                            'ace-u-flex',
                            'ace-u-flex--direction-row',
                            'ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="invoice-overview-screen-tabs"
                            tabSet="invoice-overview-screen-tab-set"
                            defaultValue={activeTab || invoiceOverviewScreenTabs.AUDITING}
                            className={cx('ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={invoiceOverviewScreenTabs.AUDITING}
                                value={invoiceOverviewScreenTabs.AUDITING}
                            >
                                {translateScreen('tab_label.auditing')}
                            </Tab>
                            <Tab
                                name={invoiceOverviewScreenTabs.CASE_OVERVIEW}
                                value={invoiceOverviewScreenTabs.CASE_OVERVIEW}
                            >
                                {translateScreen('tab_label.case_overview')}
                            </Tab>
                            <Tab
                                name={invoiceOverviewScreenTabs.LOG}
                                value={invoiceOverviewScreenTabs.LOG}
                            >
                                {translateScreen('tab_label.log')}
                            </Tab>
                            {invoice.channel === alfInvoiceChannelTypes.MIA && (
                                <Tab
                                    name={invoiceOverviewScreenTabs.MIA_FORM}
                                    value={invoiceOverviewScreenTabs.MIA_FORM}
                                >
                                    {translateScreen('tab_label.mia_form')}
                                </Tab>
                            )}
                            <Tab
                                name={invoiceOverviewScreenTabs.COMMUNICATION}
                                value={invoiceOverviewScreenTabs.COMMUNICATION}
                            >
                                {translateScreen('tab_label.communication')}
                            </Tab>
                            {isTaskTabAvailable && (
                                <Tab
                                    name={invoiceOverviewScreenTabs.TASKS}
                                    value={invoiceOverviewScreenTabs.TASKS}
                                >
                                    {translateScreen('tab_label.tasks')}
                                    {taskCountIndicator > 0 && (
                                        <Badge
                                            className={cx([
                                                'ace-c-badge--status-notification',
                                                'global!ace-u-margin--left-8',
                                            ])}
                                        >
                                            {taskCountIndicator}
                                        </Badge>
                                    )}
                                </Tab>
                            )}
                        </TabBar>
                        {isInvoiceUpdateAllowed && (
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <BurgerMenu
                                    invoice={invoice}
                                    handleInvoiceApproval={handleInvoiceApproval}
                                    serviceCaseId={match?.params?.serviceCaseId}
                                />
                            </div>
                        )}
                    </div>
                </NavigationBar>
                {failedINAConstraints && status === alfInvoiceStatusTypes.MANUAL_CHECK && (
                    <ManualCheckFailedBanner failedConstraints={failedINAConstraints} />
                )}
                {isReadOnly && (
                    <InvoiceReadOnlyBanner
                        approvedAt={invoice?.approvedAt || ''}
                        approvedBy={invoice?.approvedBy || ''}
                        approveDescription={invoice?.approveDescription}
                        approveReason={invoice?.approveReason}
                        declinedAt={invoice?.declinedAt || ''}
                        declinedBy={invoice?.declinedBy || ''}
                        declineDescription={invoice?.declineDescription}
                        declineReason={invoice?.declineReason}
                        bcErrorDescription={invoice?.bcErrorDescription}
                        invoiceStatus={invoice?.status}
                    />
                )}
                <AppLayoutMain>
                    <TabPanel for={invoiceOverviewScreenTabs.AUDITING}>
                        <InvoiceOverviewBaseDataPanel />
                        <InvoiceOverviewServicePanel
                            invoiceServices={invoiceServices}
                            handleApprovedAmountChange={handleApprovedAmountChange}
                        />
                        <ContentBlock>
                            <ContentItem className={cx('ace-c-content-item--span-8')}>
                                <InvoiceRelevantLocationsMap />
                            </ContentItem>
                            <ContentItem className={cx('ace-c-content-item--span-4')}>
                                <InvoiceDocumentUploadPanel isReadOnly={true} />
                            </ContentItem>
                        </ContentBlock>
                        {areInternalNotesAvailable && (
                            <InternalNotesPanel invoice={invoice} invoiceServices={invoiceServices} />
                        )}
                    </TabPanel>
                    <TabPanel for={invoiceOverviewScreenTabs.CASE_OVERVIEW}>
                        <SCOverviewTab />
                    </TabPanel>
                    <TabPanel for={invoiceOverviewScreenTabs.LOG}>
                        <ContentBlock>
                            <ContentItem className={cx('ace-c-content-item--span-9')}>
                                <CaseProtocolPanel />
                            </ContentItem>
                            <ContentItem className={cx('ace-c-content-item--span-3')}>
                                <FilterPanel />
                            </ContentItem>
                        </ContentBlock>
                    </TabPanel>
                    <TabPanel for={invoiceOverviewScreenTabs.MIA_FORM}>
                        <MIAFormTab />
                    </TabPanel>
                    <TabPanel for={invoiceOverviewScreenTabs.COMMUNICATION}>
                        <InvoiceCommunicationTab />
                    </TabPanel>
                    <TabPanel for={invoiceOverviewScreenTabs.TASKS}>
                        <TaskListPanel />
                    </TabPanel>
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

InvoiceOverviewScreen.propTypes = {
    invoice: PropTypes.object,
    taskCountIndicator: PropTypes.number,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    initiateInvoiceScreenRedirection: PropTypes.func.isRequired,
    services: PropTypes.array,
    initiateManualInvoiceStatusUpdateFlow: PropTypes.func.isRequired,
    initiateApprovalError: PropTypes.func.isRequired,
};

InvoiceOverviewScreen.defaultProps = {
    invoice: null,
    taskCountIndicator: 0,
    services: [],
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
        services: invoiceSelectors.getServices(state),
        taskCountIndicator: state.tasks.taskCountIndicator,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateInvoiceScreenRedirection: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_SCREEN_REDIRECTION,
        payload,
    }),
    initiateManualInvoiceStatusUpdateFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_APPROVAL_FLOW,
        payload,
    }),
    initiateApprovalError: payload => dispatch({
        type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOverviewScreen);
